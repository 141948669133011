import lodash from  'lodash'
import { Settings, Info } from 'luxon'

if (!window._) {
  window._ = lodash
}

Settings.defaultWeekSettings = {
  // Sunday = 7, Monday = 1.
  firstDay: 1,
  // Makes sure we don't lose the other information from `defaultWeekSettings`
  minimalDays: Info.getMinimumDaysInFirstWeek(),
  weekend: Info.getWeekendWeekdays(),
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios'
if (!window.axios) {
  window.axios = axios
  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  window.axios.defaults.withCredentials = true;
}

if (!window.cpyObj) {
  window.cpyObj = (obj, defaultVal = {}) => {
    if (typeof obj === 'undefined') {
      return defaultVal
    }

    return JSON.parse(JSON.stringify(obj));
  }
}

if (!document.title) {
  document.title = 'Freshability'
}

if (!window.setTitle) {
  window.setTitle = (title) => {
    document.title = title + ' - Freshability'
  }
}

import {createI18n} from 'vue-i18n';
import {languages} from '../../storage/app/translations';
import '../css/app.css';
import '../images/favicon.ico';

if (!window.i18n) {
  const messages = Object.assign(languages)


  window.i18n = createI18n({
    legacy: false,
    locale: document.querySelector('meta[name="lang"]').getAttribute('content'),
    globalInjection: true,
    fallbackLocale: 'en',
    messages
  })
}

export default {}
